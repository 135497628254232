import clsxm from '@utils/clsxm';
import type { Icon } from '.';

const RssIcon: Icon = ({ className }) => (
  <svg
    fill="currentColor"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 40 40"
    className={clsxm('h-4 w-4', className)}
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="0.5"
      fill="none"
      fillRule="evenodd"
    >
      <g id="rss" fill="currentColor" fillRule="nonzero">
        <path
          d="M36.4444274,0 L3.55557262,0 C1.59228819,0 0,1.60117404 0,3.57541468 L0,36.4245853 C0,38.398826 1.59228819,40 3.55557262,40 L36.4444274,40 C38.4077118,40 40,38.3995984 40,36.4245853 L40,3.57541468 C40,1.60117404 38.4077118,0 36.4444274,0 Z M10.4877487,33.9768668 C8.46301559,33.9768668 6.82080037,32.3247147 6.82080037,30.2894549 C6.82080037,28.2518779 8.46301559,26.602043 10.4877487,26.602043 C12.5117136,26.602043 14.154697,28.2518779 14.154697,30.2894549 C14.154697,32.3254871 12.5117136,33.9768668 10.4877487,33.9768668 Z M24.4726938,33.9768668 L20.7089638,33.9768668 C20.731239,33.6501439 20.7573546,33.3272829 20.7573546,32.9951532 C20.7573546,25.2511248 14.4903602,18.9483847 6.78853983,18.9483847 L6.78700361,18.9483847 L6.78700361,15.1636511 L6.78853983,15.1636511 C16.5665566,15.1636511 24.5218527,23.1625698 24.5218527,32.9951532 C24.5210846,33.3280553 24.4895921,33.6493715 24.4726938,33.9768668 Z M32.9026807,33.9768668 L29.3386589,33.9768668 C29.5537292,32.7773379 29.6843076,31.5500029 29.6843076,30.2894549 C29.6843076,18.8649661 20.4416622,9.5699693 9.07980644,9.5699693 C8.30401721,9.5699693 7.5405177,9.62249213 6.7862355,9.70591075 L6.7862355,6.13899241 C7.54128581,6.06715971 8.30555342,6.02158843 9.07980644,6.02158843 C22.3857439,6.02158843 33.2122283,16.9084905 33.2122283,30.2886825 C33.2122283,31.5469133 33.0870267,32.7703864 32.9026807,33.9768668 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default RssIcon;
