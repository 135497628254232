export type IconProps = React.SVGProps<SVGSVGElement>;

export type Icon = React.FC<IconProps>;

export { default as GitHubIcon } from './GitHubIcon';
export { default as MastodonIcon } from './MastodonIcon';
export { default as PatreonIcon } from './PatreonIcon';
export { default as RssIcon } from './RssIcon';
export { default as ThreadsIcon } from './ThreadsIcon';
export { default as TwitchIcon } from './TwitchIcon';
export { default as TwitterIcon } from './TwitterIcon';
export { default as YouTubeIcon } from './YouTubeIcon';
